export const nounBasedOnCount = (
  word: string,
  count: number,
  singularForm = '',
  twoToFourForm = 'а',
  fifteenToTwentyForm = 'ов',
  pluralForm = fifteenToTwentyForm,
) => {
  const hundredNormalized = Math.abs(Math.trunc(count)) % 100;
  if (hundredNormalized >= 5 && hundredNormalized <= 20) {
    return `${word}${fifteenToTwentyForm}`;
  }

  const tenNormalized = hundredNormalized % 10;
  if (tenNormalized === 1) {
    return `${word}${singularForm}`;
  }
  if (tenNormalized >= 2 && tenNormalized <= 4) {
    return `${word}${twoToFourForm}`;
  }
  return `${word}${pluralForm}`;
};
